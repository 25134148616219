<template>
  <div class="overlay app-overlay" :class="{ overlayVisible: showOverlay }">
    <portal-target class="app-overlay-target" name="app-overlay">
    </portal-target>
  </div>
</template>

<script>
import { showOverlayComputed } from "@/services/overlayService";
export default {
  computed: {
    ...showOverlayComputed,
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  width: 100%;
  min-height: 100vh;
}

.app-overlay {
  z-index: 150;
}

.overlayVisible {
  display: block;
}

.app-overlay-target {
  height: 100%;
}
</style>