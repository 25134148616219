<template>
  <div class="bookmarks">
    <div class="headings__container desktop-only">
      <BaseLabel>Titles:</BaseLabel>
      <BaseLabel>Last Viewed:</BaseLabel>
    </div>

    <SectionHeading class="heading__mobile mobile-only"
      >My Bookmarks</SectionHeading
    >

    <div class="bookmarks__content">
      <div
        v-for="bookmark of bookmarks"
        :key="bookmark.id"
        class="bookmark__row"
      >
        <div class="bookmark-title__container">
          <a
            title="Remove bookmark"
            @click.prevent="deleteBookmark(bookmark.id)"
            class="remove-bookmark__link"
            style="line-height: 30px; vertical-align: bottom"
            ><img src="@/assets/img/rm_bookmark.svg" alt="remove bookmark"
          /></a>
          <router-link
            @click.native="onGoToBookmark"
            :to="'/details/' + bookmark.song_id"
          >
            {{ bookmark.title }}
          </router-link>
        </div>
        <div class="last-viewed desktop-only">
          <a href="%s" class="title_bookmark_link">{{
            bookmark.display_date
          }}</a>
        </div>
      </div>
      <infinite-loading
        v-if="bookmarks.length"
        @infinite="onLoadMore"
      ></infinite-loading>
    </div>

    <!-- <table class="scroll bookmarks_table">
      <thead>
        <tr>
          <th>Titles:</th>
          <th>Bookmarked:</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="bookmark of bookmarks" :key="bookmark.id">
          <td>
            <a
              title="Remove bookmark"
              @click.prevent="deleteBookmark(bookmark.id)"
              class="profile_bookmark"
              style="line-height: 30px; vertical-align: bottom"
              ><img src="@/assets/img/rm_bookmark.svg" alt="remove bookmark"
            /></a>
            <router-link
              @click.native="onGoToBookmark"
              :to="'/details/' + bookmark.song_id"
            >
              {{ bookmark.title }}
            </router-link>
          </td>
          <td>
            <a href="%s" class="title_bookmark_link">{{
              bookmark.display_date
            }}</a>
          </td>
        </tr>
        <tr v-if="!endReached && bookmarks.length">
          <td>
            <infinite-loading @infinite="onLoadMore"></infinite-loading>
          </td>
        </tr>
      </tbody>
    </table> -->
  </div>
</template>

<script>
import SectionHeading from "@/components/common/SectionHeading";
import { getUserSongBookmarks, deleteSongBookmark } from "@/api/bookmarkApi.js";
import { userComputed } from "@/services/userService.js";
import { API_STATUS } from "@/constants/apiStatus.js";
import { closeMenu } from "@/services/menuService.js";
const { IDLE, PENDING, SUCCESS, ERROR } = API_STATUS;

export default {
  name: "MenuBookmarks",
  components: {
    SectionHeading,
  },
  data() {
    return {
      isInitialised: false,
      bookmarks: [],
      deleteBookmarkApiStatus: IDLE,
      deletingBookmarkId: null,
      endReached: false,
    };
  },
  computed: {
    ...userComputed,
    isDeletingBookmark() {
      return this.deleteBookmarkApiStatus === PENDING;
    },
  },
  methods: {
    onGoToBookmark() {
      closeMenu();
    },
    async fetchBookmarks(user_id, $state) {
      try {
        this.isInitialised = true;
        const response = await getUserSongBookmarks(
          user_id,
          this.$options.last_bookmark_doc
        );
        console.log("resposne bookmarks", response);
        if (response.empty) {
          $state && $state.complete();
          return;
        }

        let bookmarks = [];
        this.$options.last_bookmark_doc =
          response.docs[response.docs.length - 1];
        response.docs.forEach((doc) => {
          let data = doc.data();
          data.display_date = new Intl.DateTimeFormat("en-US").format(
            data.created_at.toDate()
          );
          bookmarks.push(data);
        });
        this.bookmarks = [...this.bookmarks, ...bookmarks];
        // Get last document to server as a cursor for load more

        if ($state) {
          $state.loaded();
        }
      } catch (error) {
        console.log("bookmarks fetch error");
        console.error(error);
      }
    },
    async onLoadMore($state) {
      await this.fetchBookmarks(this.user.uid, $state);
      this.endReached = true;
    },
    async deleteBookmark(bookmark_id) {
      try {
        if (this.isDeletingBookmark) return;
        this.deletingBookmarkId = bookmark_id;
        this.deleteBookmarkApiStatus = PENDING;
        await deleteSongBookmark(bookmark_id);
        this.bookmarks = this.bookmarks.filter(
          (bookmark) => bookmark.id !== bookmark_id
        );
        this.deleteBookmarkApiStatus = SUCCESS;
      } catch (error) {
        console.error(error);
        this.deleteBookmarkApiStatus = ERROR;
      } finally {
        this.deletingBookmarkId = null;
      }
    },
  },
  created() {
    this.fetchBookmarks(this.user.uid);
  },
};
</script>

<style lang="scss" scoped>
.bookmarks {
  @media screen and (max-width: 768px) {
    padding: 2rem;
  }
}

.bookmark__row {
  margin-bottom: 0.5rem;
  @media screen and (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.remove-bookmark__link {
  margin-right: 1rem;
  transform: translateY(8px);
}

.headings__container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.bookmark-title__container {
  display: flex;
  align-items: center;
}

.bookmarks__content {
  overflow: auto;
  max-height: 20rem;
}
</style>