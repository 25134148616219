<template>
  <div>
    <div v-if="screen.width >= 768" class="desktop-menu">
      <DesktopMenu />
    </div>
    <div v-else class="mobile-menu">
      <MobileMenu />
    </div>
  </div>
</template>

<script>
import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";
import { resizeComputed } from "@/services/resizeService";

export default {
  components: {
    DesktopMenu,
    MobileMenu,
  },
  computed: {
    ...resizeComputed,
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 768px) {
  .desktop-menu {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .mobile-menu {
    display: none;
  }
}
</style>