import Vue from 'vue';

const initialState = {
  showOverlay: false,
};

const state = Vue.observable(initialState);

export const showOverlayComputed = {
  showOverlay: {
    get() {
      return state.showOverlay;
    },
  },
};

export const showOverlay = () => {
  state.showOverlay = true;
  document.body.classList.add('overflow-hidden');
  document.documentElement.classList.add('overflow-hidden');
};
export const hideOverlay = () => {
  state.showOverlay = false;
  document.body.classList.remove('overflow-hidden');
  document.documentElement.classList.remove('overflow-hidden');
};
