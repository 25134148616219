<template>
  <div>
    <!-- <div class="overlay" :style="overlayStyle"> -->
    <portal to="app-overlay">
      <div class="row mobile-menu">
        <div :class="$style['menu-close-icon__container']">
          <a @click.prevent="onCloseIcon" href="#" class="form-close"
            ><img
              class="mobile_form_close"
              src="@/assets/img/Iconcolor.svg"
              alt="close"
          /></a>
        </div>
        <nav class="mobile-menu__nav">
          <ul v-show="!activeTab" class="mobile-menu">
            <li
              class="profile_tab_handler"
              @click.prevent="activateTab('PROFILE')"
            >
              Profile
            </li>
            <li
              class="profile_tab_handler"
              @click.prevent="activateTab('BOOKMARKS')"
            >
              Bookmarks
            </li>
            <li
              class="profile_tab_handler"
              @click.prevent="activateTab('CHANGE_PASSWORD')"
            >
              Password
            </li>
            <li>
              <Logout @logout="onLogout" />
            </li>
          </ul>
          <div v-show="activeTab" class="mobile-menu__content">
            <component :is="selectedComponent" />
          </div>
        </nav>
      </div>
    </portal>
    <!--  </div> -->
  </div>
</template>

<script>
import { userComputed } from "@/services/userService.js";
import { closeMenu, isMenuOpenComputed } from "@/services/menuService.js";
import MenuBookmarks from "./MenuBookmarks";
import MenuProfile from "./MenuProfile";
import Logout from "./Logout";
import ChangePassword from "./ChangePassword";
import { hideOverlay } from "@/services/overlayService";
const COMPONENT_CONFIG = {
  PROFILE: MenuProfile,
  BOOKMARKS: MenuBookmarks,
  CHANGE_PASSWORD: ChangePassword,
};

export default {
  name: "MobileMenu",
  components: {
    Logout,
    MenuBookmarks,
    MenuProfile,
    ChangePassword,
  },
  data() {
    return {
      activeTab: "",
    };
  },
  computed: {
    ...userComputed,
    ...isMenuOpenComputed,
    selectedComponent() {
      return this.activeTab
        ? Reflect.get(COMPONENT_CONFIG, this.activeTab)
        : "div";
    },
    overlayStyle() {
      return {
        display: this.isMenuOpen ? "block" : "none",
      };
    },
  },
  methods: {
    onLogout() {
      closeMenu();
    },
    activateTab(tabName) {
      this.activeTab = tabName;
    },
    onCloseIcon() {
      if (this.activeTab) {
        this.activeTab = "";
        return;
      }

      hideOverlay();
      closeMenu();
    },
  },
};
</script>

<style lang="scss" module>
.menu-close-icon__container {
  position: absolute;
  top: 2.7rem;
  right: 1.7rem;
  width: 30px;
  height: 30px;
}
</style>

<style lang="scss" scoped>
.mobile-menu {
  list-style-type: none;
  height: 100%;

  li {
    margin-bottom: 2rem;
    font-size: 30px;
    font-weight: bold;
  }
}

.mobile-menu__nav {
  position: relative;
  top: 10rem;
}

@media screen and (min-width: 768px) {
  .mobile-menu {
    display: none;
  }
}
</style>