<template>
  <form method="post" id="user_password">
    <div class="form-fields__container">
      <div class="form-block">
        <BaseStack vertical>
          <BaseLabel> New Password (required): </BaseLabel>
          <BaseInput
            class="menu-style"
            type="password"
            name="new_password"
            placeholder="New password..."
            v-model="new_password"
          />
        </BaseStack>
      </div>
      <div class="form-block">
        <BaseStack vertical>
          <BaseLabel> Repeat New Password (required) </BaseLabel>
          <BaseInput
            class="menu-style"
            type="password"
            name="new_password2"
            placeholder="Confirm password..."
            v-model="confirm_password"
          />
        </BaseStack>
      </div>
      <div class="form-block">
        <BaseStack vertical>
          <BaseLabel> Old Password (required)</BaseLabel>
          <BaseInput
            class="menu-style"
            type="password"
            name="current_password"
            placeholder="Current password..."
            v-model="current_password"
          />
        </BaseStack>
      </div>
    </div>
    <div class="form-block">
      <BaseButton
        variant="primary-outline"
        :disabled="isUpdatingPassword"
        @click.prevent="changePassword"
      >
        {{ isUpdatingPassword ? "Saving..." : "Save Changes" }}
      </BaseButton>

      <p v-if="isUpdatingPasswordError">
        Could not update password. Please try again.
      </p>
    </div>
    <!--<table>
      <thead>
        <tr>
          <th class="width35">New Password:</th>
          <th class="width35">Re-enter New Passowrd:</th>
          <th class="width35">Current Passowrd:</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="width35">
            <input
              class="menu-style"
              type="password"
              name="new_password"
              placeholder="New password..."
              v-model="new_password"
            />
          </td>
          <td class="width35">
            <input
              class="menu-style"
              type="password"
              name="new_password2"
              placeholder="Confirm password..."
              v-model="confirm_password"
            />
          </td>
          <td class="width35">
            <input
              class="menu-style"
              type="password"
              name="current_password"
              placeholder="Current password..."
              v-model="current_password"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <input
      class="menu-style-button"
      type="submit"
      value="Save Changes"
      @click.prevent="changePassword"
    />-->
  </form>
</template>

<script>
import { updatePassword, reauthenticateWithPassword } from "@/api/authApi.js";
import { userComputed } from "@/services/userService.js";
import { API_STATUS } from "@/constants/apiStatus.js";
const { IDLE, PENDING, SUCCESS, ERROR } = API_STATUS;
export default {
  name: "ChangePassword",
  data() {
    return {
      new_password: "",
      confirm_password: "",
      current_password: "",
      updatePasswordApiStatus: IDLE,
    };
  },
  computed: {
    ...userComputed,
    isUpdatingPassword() {
      return this.updatePasswordApiStatus === PENDING;
    },
    isUpdatingPasswordError() {
      return this.updatePasswordApiStatus === ERROR;
    },
  },
  methods: {
    async changePassword() {
      try {
        const { new_password, confirm_password, current_password } = this;
        if (new_password !== confirm_password) {
          alert("Passwords do not match");
          return;
        }
        this.updatePasswordApiStatus = PENDING;

        const res = await reauthenticateWithPassword(
          this.user.email,
          current_password
        );
        console.log("reauth res", res);
        const response = await updatePassword(new_password);
        console.log("password", { response });
        this.updatePasswordApiStatus = SUCCESS;
      } catch (error) {
        console.error(error);
        this.updatePasswordApiStatus = ERROR;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-block {
  padding: 0.5rem 1.5rem;
}

@media screen and (min-width: 768px) {
  .form-fields__container {
    display: flex;
    flex-wrap: wrap;

    * > .form-block {
      flex-basis: 50%;
    }
  }
}
</style>
