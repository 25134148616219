<template>
  <component class="section-heading" :is="component"><slot /></component>
</template>

<script>
export default {
  props: {
    component: {
      type: String,
      default: "div",
    },
  },
};
</script>

<style lang="scss" scoped>
.section-heading {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}
</style>