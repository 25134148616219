<template>
  <div>
    <!--<div class="overlay" :style="overlayStyle">-->

    <portal to="app-overlay">
      <header class="menu-header">
        <div class="container">
          <div class="links-container">
            <a class="menu_button" href=""
              ><img
                :src="menuButtonImgSrc"
                alt="logo"
                @click.prevent="onCloseMenu"
            /></a>
            <div class="logo">
              <a href="index.php"
                ><img src="@/assets/img/rightsholder_logo.svg" alt="logo"
              /></a>
            </div>
          </div>
        </div>
      </header>
      <div class="container">
        <h1>Menu</h1>
        <div class="white-space"></div>
        <div class="row profile_tabber">
          <div class="menu-column-left">
            <ul style="list-style-type: none">
              <li
                class="profile_tab_handler"
                :class="{ profile_tab_handler_active: activeTab === 'PROFILE' }"
                @click.prevent="activateTab('PROFILE')"
              >
                Profile
              </li>
              <li
                class="profile_tab_handler"
                :class="{
                  profile_tab_handler_active: activeTab === 'BOOKMARKS',
                }"
                @click.prevent="activateTab('BOOKMARKS')"
              >
                Bookmarks
              </li>
              <!-- <li class="profile_tab_handler">
                                Help
                            </li> -->
              <li
                class="profile_tab_handler"
                :class="{
                  profile_tab_handler_active: activeTab === 'CHANGE_PASSWORD',
                }"
                @click.prevent="activateTab('CHANGE_PASSWORD')"
              >
                Password
              </li>
              <li>
                <Logout @logout="onLogout" />
              </li>
            </ul>
          </div>
          <div class="menu-column-right">
            <!-- profile tab -->
            <div
              class="profile_tab"
              :class="{ profile_tab_active: activeTab === 'PROFILE' }"
            >
              <MenuProfile />
            </div>
            <!-- end profile tab -->

            <!--history tab-->
            <div
              class="profile_tab"
              :class="{ profile_tab_active: activeTab === 'BOOKMARKS' }"
            >
              <MenuBookmarks v-if="activeTab === 'BOOKMARKS' && isMenuOpen" />
            </div>
            <!-- end history tab -->

            <!--password tab-->
            <div
              class="profile_tab"
              :class="{ profile_tab_active: activeTab === 'CHANGE_PASSWORD' }"
            >
              <ChangePassword />
            </div>
            <!--end password tab-->
          </div>
        </div>
      </div>
    </portal>
    <!-- </div>-->
  </div>
</template>

<script>
import { userComputed } from "@/services/userService.js";
import { closeMenu, isMenuOpenComputed } from "@/services/menuService.js";
import { hideOverlay } from "@/services/overlayService";

import MenuBookmarks from "./MenuBookmarks";
import MenuProfile from "./MenuProfile";
import Logout from "./Logout";
import ChangePassword from "./ChangePassword";
import CloseMenu from "@/assets/img/close_menu.svg";
import MenuIcon from "@/assets/img/menu.svg";

export default {
  name: "DesktopMenu",
  components: {
    Logout,
    MenuBookmarks,
    MenuProfile,
    ChangePassword,
  },
  data() {
    return {
      activeTab: "PROFILE",
    };
  },
  computed: {
    ...userComputed,
    ...isMenuOpenComputed,
    overlayStyle() {
      return {
        display: this.isMenuOpen ? "block" : "none",
      };
    },
    menuButtonImgSrc() {
      return this.isMenuOpen ? CloseMenu : MenuIcon;
    },
  },
  methods: {
    onLogout() {
      closeMenu();
    },
    activateTab(tabName) {
      this.activeTab = tabName;
    },
    onCloseMenu() {
      hideOverlay();
      closeMenu();
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.menu-header {
  margin-top: 75px;
  margin-bottom: 50px;
}
.links-container {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .menu-header__container {
    display: none;
  }

  .profile_tab_handler {
    margin-bottom: 10px;
  }
}

.profile_tab_handler {
  font-weight: 600;
}

.menu-column-left {
  // float: left;
  // width: 20%;
  flex-basis: 20%;
  color: #45507f;
  font-size: 24px;
  line-height: 44px;
  text-decoration: none;
  border-right: 2px solid #d5d6e3;

  /* margin-right: 30px; */
  .menu-column-left a.active {
    color: #bd7eee;
  }
}

.menu-column-left ul {
  margin: 0;
  padding: 0;
}

.menu-column-right {
  // float: left;
  flex-basis: calc(70% - 5%);
  margin-left: 5%;
  color: #45507f;
  /* font-size: 4px; */
}

.profile_tabber {
  display: flex;
}
</style>
