<template>
  <div>
    <header id="header">
      <div class="container">
        <nav id="nav">
          <div class="links-container">
            <a class="menu_button" href=""
              ><img
                :src="menuButtonImgSrc"
                alt="logo"
                @click.prevent="onMenuOpen"
            /></a>
            <component
              :is="menuBackComponent"
              :style="backToSearchIconStyle"
              to="/"
              class="back-to-search"
              href=""
              @click.prevent="onBackClicked"
            >
              <img :src="backIconSrc" alt="Back to search icon link" />
            </component>
          </div>
          <div class="logo">
            <a href="/"
              ><img src="@/assets/img/rightsholder_logo.svg" alt="logo"
            /></a>
          </div>
        </nav>
      </div>
    </header>
    <slot />
  </div>
</template>

<script>
import { isMenuOpenComputed, toggleMenu } from "@/services/menuService.js";
import { showOverlay } from "@/services/overlayService";
import CloseMenu from "@/assets/img/close_menu.svg";
import MenuIcon from "@/assets/img/menu.svg";
import BackIcon from "@/assets/img/back_button.svg";
export default {
  computed: {
    ...isMenuOpenComputed,
    menuButtonImgSrc() {
      return this.isMenuOpen ? CloseMenu : MenuIcon;
    },
    isDetailsInRoutePath() {
      return this.$route.path.startsWith("/details");
    },
    backIconSrc() {
      return this.isDetailsInRoutePath ? BackIcon : CloseMenu;
    },
    menuBackComponent() {
      return this.isDetailsInRoutePath ? "a" : "router-link";
    },
    backToSearchIconStyle() {
      return {
        display: this.$route.name === "Search" ? "none" : "block",
      };
    },
  },
  methods: {
    onMenuOpen() {
      toggleMenu();
      showOverlay();
    },
    onBackClicked() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
#header {
  @include tablet-landscape-only {
    margin-top: 75px;
  }
}
@include mobile {
  .logo {
    display: none;
  }
}

.links-container {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
}

.back-to-search {
  @include tablet {
    display: none !important;
  }
}

#nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>