<template>
  <form method="post" id="user_profile">
    <div class="form-fields__container">
      <div class="form-block">
        <BaseStack vertical>
          <BaseLabel> Full Name (required): </BaseLabel>
          <BaseInput
            class="menu-style"
            type="text"
            name="name"
            placeholder="Fullname..."
            v-model="form.name"
          />
        </BaseStack>
      </div>
      <div class="form-block">
        <BaseStack vertical>
          <BaseLabel> Email (required): </BaseLabel>
          <BaseInput
            class="menu-style"
            type="text"
            name="name"
            placeholder="Fullname..."
            v-model="form.email"
          />
        </BaseStack>
      </div>
      <div class="form-block">
        <BaseStack vertical>
          <BaseLabel> Title / Role:</BaseLabel>
          <BaseInput
            class="menu-style"
            type="text"
            name="title"
            placeholder="Title/Role..."
            v-model="form.company_title"
          />
        </BaseStack>
      </div>
      <div class="form-block">
        <BaseStack vertical>
          <BaseLabel> Company: </BaseLabel>
          <BaseInput
            class="menu-style"
            type="text"
            name="company"
            placeholder="Company name..."
            v-model="form.company"
          />
        </BaseStack>
      </div>
    </div>
    <div class="form-block">
      <BaseButton
        variant="primary-outline"
        :disabled="isSavingProfile"
        @click.prevent="saveProfile"
      >
        {{ isSavingProfile ? "Saving..." : "Save Changes" }}
      </BaseButton>

      <p v-if="saveProfileApiStatus === API_STATUS.ERROR">
        Could not update profile. Please try again.
      </p>
    </div>
  </form>
</template>

<script>
import { userComputed, updateUser } from "@/services/userService.js";
import * as userApi from "@/api/userApi.js";
import { API_STATUS } from "@/constants/apiStatus.js";
const { IDLE, PENDING, SUCCESS, ERROR } = API_STATUS;
export default {
  name: "MenuProfile",
  data() {
    return {
      form: {
        name: "",
        email: "",
        company: "",
        company_title: "",
      },
      saveProfileApiStatus: IDLE,
    };
  },
  computed: {
    ...userComputed,
    isSavingProfile() {
      return this.saveProfileApiStatus === PENDING;
    },
  },
  methods: {
    async saveProfile() {
      try {
        if (this.isSavingProfile) return;
        this.saveProfileApiStatus = PENDING;
        const user = await userApi.updateUser({
          ...this.user,
          ...this.form,
        });
        await updateUser(user);
        this.saveProfileApiStatus = SUCCESS;
      } catch (error) {
        console.error(error);
        this.saveProfileApiStatus = ERROR;
      }
    },
  },
  created() {
    this.API_STATUS = API_STATUS;
    const {
      name = "",
      email = "",
      company = "",
      company_title = "",
    } = this.user;
    this.form = {
      name,
      email,
      company,
      company_title,
    };
  },
};
</script>

<style lang="scss" scoped>
.form-block {
  padding: 0.5rem 1.5rem;
}

@media screen and (min-width: 767px) {
  .form-fields__container {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .form-block {
    }
  }
}
</style>
