import Vue from 'vue';
import { hideOverlay, showOverlay } from './overlayService';
const initialState = {
  isMenuOpen: false,
};

const state = Vue.observable(initialState);

export const isMenuOpenComputed = {
  isMenuOpen: {
    get() {
      return state.isMenuOpen;
    },
  },
};

export const openMenu = () => {
  state.isMenuOpen = true;
  showOverlay();
};
export const closeMenu = () => {
  state.isMenuOpen = false;
  hideOverlay();
};
export const toggleMenu = () => {
  state.isMenuOpen ? closeMenu() : openMenu();
};
